@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #FFC2C7;
  --background: #fff;
  --background: #f7f7f7;
  --surface: theme('colors.white');
  --surface: #FFD8DB;
  //--surface: #FFC2C7;
  --primary: #FFC0CB;
  --primary: #030202;
  --primary: #FF647D;
  --primary: #C6999D;
  --primary-light: #FFB842;
  --primary-dark: #BA7400;
  --text: #111;
}

@import "loading";
@import "fonts";

html,
body,
#root {
  @apply h-full;
}

body {
  @apply font-OpenSans;
  @apply text-text;
  @apply bg-background;
  @apply bg-[url("../assets/blob-1.svg"),url("../assets/blob-2.svg"),url("../assets/blob-3.svg")];
  @apply bg-[length:200px_auto,200px_auto,200px_auto];
  @apply bg-[position:80%_80%,20%_30%,40%_70%];
  @apply bg-no-repeat bg-fixed;
}

h1,
h2,
h3,
h4,
h5 {
  //@apply font-handwriting font-semibold;
  // @apply text-xl;
}

h1 {
  @apply text-2xl;
  @apply font-bold;
}

a {
  @apply text-primary;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="mail"],
textarea {
  @apply w-full;
  @apply p-1;
  @apply rounded;
  @apply bg-background;
  @apply text-sm;
}