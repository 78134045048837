.loader {
  --size: 24px;
  &.big {
    --size: 48px
  }
  width: var(--size);
  height: var(--size);
  display: inline-block;
  position: relative;

  &:after,
  &:before {
    content: '';
    box-sizing: border-box;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 2px solid var(--primary);
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }

  &:after {
    animation-delay: 1s;
  }
}

.bouncing {
  animation: bouncing 2s ease infinite;
}

.loading-shadow {
	height: 2px;
  width: 50px;
	border-radius: 50%;
	background: rgba(0,0,0,0.2);
	box-shadow: 0 0 0px 2px rgba(0,0,0,0.2);
	animation: shadow 2s ease infinite;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes bouncing {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.8)   translateY(6px); }
  30%  { transform: scale(.9,1.1)   translateY(-50%); }
  50%  { transform: scale(1.1,.9) translateY(4px); }
  57%  { transform: scale(1,1)      translateY(-4px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

@keyframes shadow {
	0%, 64%, 100% {
		transform: scaleX(1);
	}
	30% {
		transform: scaleX(0.6);
	}
}