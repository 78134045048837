$open-sans-font-path: "../fonts/OpenSans/OpenSans";
$caveat-font-path: "../fonts/caveat/Caveat";

$OpenSansWeights: (
  "Light": 300,
  "Regular": 400,
  "Medium": 500,
  "SemiBold": 600,
  "Bold": 700,
  "ExtraBold": 800
);

@each $name, $weight in $OpenSansWeights {
  @font-face {
    src: local('OpenSans'),
    url("../fonts/OpenSans/OpenSans-#{$name}.ttf") format("truetype");
    font-family: "OpenSans";
    font-weight: $weight;
  }
}

$CaveatWeights: (
  "Regular": 400,
  "Medium": 500,
  "SemiBold": 600,
  "Bold": 700,
);

@each $name, $weight in $CaveatWeights {
  @font-face {
    src: local('Caveat'),
    url("../fonts/caveat/Caveat-#{$name}.ttf") format("truetype");
    font-family: "Caveat";
    font-weight: $weight;
  }
}